import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton, Typography } from '@mui/material'
import useStyles from './styles'
import classnames from 'classnames'

interface BackButtonProps {
  handleBack?: () => void
  title?: string
  border?: boolean
  disabledBack?: boolean
  subTitle?: string
  className?: string
  titleClass?: string
  right?: React.ReactNode
}

const BackButton: React.FC<BackButtonProps> = ({
  handleBack,
  title = 'BACK',
  border,
  disabledBack,
  subTitle,
  className,
  titleClass,
  right,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const back = useCallback(() => {
    if (handleBack) {
      handleBack()
    } else {
      navigate(-1)
    }
  }, [])
  return (
    <Box className={classnames(classes.container, { [classes.border]: border }, className)}>
      <Box className={classes.backButton}>
        {!disabledBack && (
          <IconButton onClick={back} disabled={disabledBack}>
            <ArrowBackIcon htmlColor="#211F1F" />
          </IconButton>
        )}
        <Typography className={classnames(classes.backText, titleClass)}>{title}</Typography>
        {right}
      </Box>
      {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
    </Box>
  )
}
export default BackButton
