import { FileITF } from '@/types/survey'
import React, { useCallback, useState } from 'react'
import useSetState from '@hooks/useSetState'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Close, FileDownloadOutlined, InsertDriveFile } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import DragUploadFile, { DragUploadSuccessFile } from '@component/Form/DragUploadFile'
import CustomTextField from '@component/Form/CustomTextField'

const useStyles = makeStyles(() => {
  return {
    pager: {
      width: 1000,
      maxHeight: '90%',
      margin: 32,
      maxWidth: 'none',
    },
    subTitle: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    box: {
      backgroundColor: '#fff',
      padding: '16px 0px 16px !important',
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
    },
    right: {
      flex: 1,
      height: '100%',
      overflow: 'scroll',
      padding: '10px 30px 24px',
    },
    btn: {
      marginTop: 24,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    fileItem: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 38,
    },
    fileName: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: 16,
      backgroundColor: '#F5F5F5',
      borderRadius: 6,
      marginRight: 24,
    },
  }
})

interface Props {
  onClose?: () => void
  title?: string
  gId?: string
  bId?: string
  isResponses?: boolean
  isDesc?: boolean
  onSave?: (note: string, files: FileITF[]) => void
  files: FileITF[]
  note?: string
}

const UploadContent: React.FC<Props> = ({
  onClose,
  gId,
  bId,
  onSave,
  isResponses,
  files: filesT,
  note,
  isDesc,
  title,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [state, setState] = useSetState({
    noteText: note || '',
    files: filesT || [],
  })
  const { noteText, files } = state
  const removeDialog = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])
  const onClickClose = useCallback(() => {
    removeDialog()
  }, [removeDialog])

  const onClick = (event) => {
    event.stopPropagation()
  }
  const onSaveBtn = () => {
    onSave?.(noteText, files)
    onClickClose()
  }
  const onDelete = (files: DragUploadSuccessFile[]) => {
    setState({ files: files.map((file) => ({ Name: file.fileName, Link: file.fileUrl })) })
  }
  const onSuccess = (files: DragUploadSuccessFile[]) => {
    setState({ files: files.map((file) => ({ Name: file.fileName, Link: file.fileUrl })) })
  }
  return (
    <Dialog open={open} disableEscapeKeyDown onClick={onClick} classes={{ paper: classes.pager }}>
      <DialogTitle id="scroll-dialog-title">
        <Box className={classes.subTitle}>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            {title ? title : isResponses ? 'Download' : 'Content Upload'}
          </Typography>
          <IconButton onClick={onClickClose}>
            <Close htmlColor="#211F1F" />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.box}>
        <Box className={classes.right}>
          {!isDesc && (
            <CustomTextField
              style={{ width: '100%', marginTop: 0 }}
              label="Note"
              value={noteText}
              disabled={!!isResponses}
              placeholder="Enter here..."
              name="Details"
              onInputChange={(value) => setState({ noteText: value })}
              variant="outlined"
              multiline
            />
          )}
          {isDesc && <Typography>{note}</Typography>}
          {isResponses ? (
            <Box>
              {files.map((item) => {
                return (
                  <Box key={item.Link} className={classes.fileItem}>
                    <Box className={classes.fileName}>
                      <InsertDriveFile htmlColor="#1976D2" />
                      <Typography style={{ flex: 1, paddingLeft: 16 }}>{item?.Name}</Typography>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(item?.Link, '_blank')
                      }}
                    >
                      DOWNLOAD
                      <FileDownloadOutlined sx={{ marginLeft: 8 }} />
                    </Button>
                  </Box>
                )
              })}
            </Box>
          ) : (
            <DragUploadFile
              defaultUrl={files?.map((file) => ({ fileUrl: file.Link, fileName: file.Name }))}
              filePath={`Survey/${gId}/PhysicalSpace`}
              multiline
              onDelete={onDelete}
              onSuccess={onSuccess}
            />
          )}
          {!isResponses && (
            <Box className={classes.btn}>
              <Button
                variant="outlined"
                color="primary"
                style={{ fontSize: 14, marginRight: 16 }}
                onClick={onClickClose}
              >
                CANCEL
              </Button>
              <Button variant="contained" color="primary" style={{ fontSize: 14 }} onClick={onSaveBtn}>
                SAVE
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UploadContent
