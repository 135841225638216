import * as React from 'react'
import { useState, useCallback, useRef, useLayoutEffect, useMemo } from 'react'
import { Box, Button, Checkbox, TextField, Collapse, IconButton, Switch, Typography, Tooltip } from '@mui/material'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AddCircle, ChevronRight, ExpandMore, DragHandle } from '@mui/icons-material'
import { EquipmentFrom, MaterialKey, MaterialObj } from '@constant/qrcode'
import classnames from 'classnames'
import useStyles from './styles'
import QuestionTable, { TEMPLATE as QuestionTEMPLATE } from './QuestionTable'
import EquipmentModal from '@pages/roundsEngine/equipment/EquipmentModal'
import { DragDropContext, Droppable, DropResult, Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { PRINTKEY } from './EngineeringBuilder'
import { formatDate } from '@utils/common'
import { checkQrSignId } from '@api'
import useSetState from '@hooks/useSetState'
import AutoClosePMModal, { WorkOrderTemplateITF } from '@component/Modal/AutoClosePMModal'
import Images from '@assets/images'

const language = 'en'
const TEMPLATE = {
  QrType: 'EquipmentInspection',
  QrCodeId: '',
  EquipmentName: '',
  EquipmentType: '',
  EquipmentId: '',
  Required: false,
  AlertRequired: false,
  ScanRequired: false,
  PhotoRequired: true,
  VideoRequired: true,
  InstallationNote: '',
  Floor: '',
  MechanicalSpace: '',
  Quantity: '',
  Material: '3mm Sintra',
  Size: '5 × 8',
  Orientation: 'Landscape',
  Color: 'Light Gray',
  PrintTemplate: '',
  FileFormat: 'eps',
  FormTemplateId: '',
  FormTemplateFields: [QuestionTEMPLATE],
  NewQr: true,
  SignId: '',
  TagType: '',
  PageHeader: 'Equipment Condition',
}

export type EquipmentsItem = typeof TEMPLATE & Record<string, any>

export const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  userSelect: 'none',
  background: isDragging ? '#EDF4FB' : 'white',
  ...draggableStyle,
})

interface Props {
  configEquipment: any[]
  equipments: EquipmentsItem[]
  changeRef: React.MutableRefObject<any>
  action: boolean
  setAction: React.Dispatch<React.SetStateAction<boolean>>
  showPrint: boolean
  colSpan: number
  roundType: EquipmentFrom
  isInstallation: boolean
  gId: string
  bId: string
  setData: React.Dispatch<React.SetStateAction<any>>
  SREnable: boolean
  SRDisabled: boolean
  isAngus: boolean
  isBE: boolean
  linkedPM: WorkOrderTemplateITF[]
}

const EquipmentTable = React.memo((props: Props) => {
  const { configEquipment, equipments, changeRef, action, setAction, showPrint, colSpan, roundType, setData } = props
  const { isInstallation, gId, bId, SREnable, SRDisabled, isAngus, isBE, linkedPM } = props
  const classes = useStyles()
  const [, setRender] = useState(false)
  const [{ showModal, showAutoClosePM }, setShowModal] = useSetState({ showModal: false, showAutoClosePM: false })
  const ref = useRef<HTMLElement>()

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      ref.current?.scrollIntoView(true)
      clearTimeout(timer)
    }, 0)
  }, [equipments.length])

  const disabledArr = useMemo(() => equipments.map((v) => v.EquipmentId), [equipments.length])
  const { linkedPMText, hasLink } = useMemo(() => {
    const hasLink = linkedPM.length > 0
    return {
      hasLink,
      linkedPMText: hasLink
        ? `Round linked to  [${(linkedPM || []).map((item) => item.Title).join(',')}]`
        : 'Link to PM',
    }
  }, [linkedPM])
  const onSave = useCallback(
    (templates: WorkOrderTemplateITF[]) => {
      changeRef.current.isChanges = true
      setData((pre) => ({ ...pre, LinkedPM: templates || [] }))
    },
    [changeRef, setData]
  )
  const onAdd = useCallback(
    (data: Record<string, any>[]) => {
      const ids = equipments.map((v) => v.EquipmentId)
      data.forEach((element, i) => {
        if (!ids.includes(element.EquipmentId)) {
          QuestionTEMPLATE.Code = `${String(Date.now())}-${i}`

          if (element?.Fields) {
            element.FormTemplateFields = element?.Fields
          }
          equipments.push({
            ...JSON.parse(JSON.stringify(TEMPLATE)),
            ...element,
            SignId: '',
            open: true,
            intoView: i === 0,
          })
        }
      })
      changeRef.current.isChanges = true
      setShowModal({ showModal: false })
      setAction((v) => !v)
    },
    [equipments]
  )

  const booleanChange = (equipmentsIndex: number, name: string, value: boolean) => {
    equipments[equipmentsIndex][name] = value
    changeRef.current.isChanges = true
    if (name === 'NewQr' && value === true) {
      equipments[equipmentsIndex]['SignId'] = ''
    }
    setRender((v) => !v)
    if (name === 'checked') {
      setAction((v) => !v)
    }
  }

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {}>,
      equipmentsIndex: number,
      type?: string,
      option?: any
    ) => {
      const { name = type, value: newValue } = event.target as any
      const rowValue = option || newValue
      // @ts-ignore
      const value = typeof rowValue === 'string' ? rowValue : rowValue.Name
      // @ts-ignore
      const displayText =
        typeof rowValue === 'string' ? rowValue : rowValue.DisplayText?.[language] || rowValue?.FileName
      const pre = equipments[equipmentsIndex]
      if (['Quantity', 'SignId'].includes(name) && !/^[0-9]*$/.test(value)) {
        return pre
      }
      pre[name] = value
      if (!pre.label) pre.label = {}
      pre.label[name] = displayText
      const { Material } = pre
      if (name === 'Material') {
        pre['Size'] = MaterialObj[Material as MaterialKey]['default']
      }
      changeRef.current.isChanges = true
      setRender((v) => !v)
    },
    [equipments]
  )

  const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, equipmentsIndex: number) => {
    const { name, value } = e.target
    if (name === 'SignId') {
      if (value) {
        equipments[equipmentsIndex]['SignIdChecked'] = false
        checkQrSignId({ gId, bId, sId: value })
          .then(() => {
            equipments[equipmentsIndex]['SignIdChecked'] = true
          })
          .catch(() => {
            equipments[equipmentsIndex]['SignIdChecked'] = false
          })
          .finally(() => {
            setRender((v) => !v)
          })
      }
    }
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const [removed] = equipments.splice(source.index, 1)
    equipments.splice(destination.index, 0, removed)
    setRender((v) => !v)
  }

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <Box margin={1} className={classnames(classes.borderLeft)}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="EquipmentTable">
                {(provided) => (
                  <Table
                    className={classnames({ [classes.table]: true })}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <colgroup>
                      <col width={200} />
                      {configEquipment.map((v, i) => {
                        return <col key={i} width={v.width || 182} />
                      })}
                    </colgroup>

                    <TableHead className={classnames(classes.tableHead, classes.stickyHeader)}>
                      <TableCell key="check" classes={{ root: classes.cell }} />
                      {configEquipment.map((v) => {
                        let title = v.title
                        if (!showPrint && PRINTKEY.includes(v.name)) {
                          title = ''
                        }
                        return (
                          <TableCell key={v.title} classes={{ root: classes.cell }}>
                            {title}
                          </TableCell>
                        )
                      })}
                    </TableHead>

                    <TableBody>
                      {equipments.map((data: EquipmentsItem, equipmentsIndex) => {
                        const { Material, EquipmentId, FormTemplateFields, QrCodeId } = data
                        const { EquipmentType, Floor, MechanicalSpace, PhotoRequired, VideoRequired } = data
                        const { checked = false, open = false, intoView } = data
                        data.canSave = ![EquipmentType, Floor, MechanicalSpace].some((v) => ['', undefined].includes(v))
                        const key = EquipmentId || QrCodeId
                        return (
                          <>
                            <Draggable key={key} draggableId={key} index={equipmentsIndex}>
                              {(draggableProvided, draggableSnapshot) => (
                                <TableRow
                                  key={key}
                                  className={classnames(classes.tableRow, { [classes.checked]: checked })}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  //@ts-ignore
                                  style={getItemStyle(
                                    draggableSnapshot.isDragging,
                                    draggableProvided.draggableProps.style
                                  )}
                                >
                                  <TableCell ref={intoView ? ref : null}>
                                    <Box className={classes.firstCell}>
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        disabled={isInstallation}
                                        onClick={() => booleanChange(equipmentsIndex, 'open', !open)}
                                      >
                                        {open ? (
                                          <ExpandMore className={classes.color} />
                                        ) : (
                                          <ChevronRight className={classes.color} />
                                        )}
                                      </IconButton>
                                      <Box sx={{ minWidth: 60 }}>Stop {equipmentsIndex + 1}</Box>
                                      <Checkbox
                                        checked={!!checked}
                                        onChange={() => booleanChange(equipmentsIndex, 'checked', !checked)}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        disabled={isInstallation}
                                      />
                                      <div {...draggableProvided.dragHandleProps}>
                                        <IconButton size="small">
                                          <DragHandle className={classes.color} fontSize="medium" />
                                        </IconButton>
                                      </div>
                                    </Box>
                                  </TableCell>

                                  {configEquipment.map((v, j) => {
                                    const { name, option = [], isObj, isBoolean, isMax, readOnly, singleChoice } = v
                                    if (!showPrint && PRINTKEY.includes(name)) return null

                                    let options = option
                                    if (name === 'Size') {
                                      if (Material === 'NA') {
                                        options = ['NA']
                                      } else {
                                        const key = Material as MaterialKey
                                        options = MaterialObj[key]?.['option'] || []
                                      }
                                    }

                                    // @ts-ignore
                                    let value = isObj ? options.find((item) => item.Name === data[name]) : data[name]
                                    if (name === 'NewQr') {
                                      value = value === false ? false : true
                                    }
                                    let disabled = false
                                    if (name === 'SignId' && data.NewQr !== false) {
                                      disabled = true
                                    }

                                    if (isBoolean) {
                                      const TagType = data.TagType
                                      const isNFC = ['NFCOnly', 'QRAndNFC'].includes(TagType)
                                      let disabled = singleChoice ? !data[name] && data[singleChoice] : false
                                      if (!isNFC && name === 'TapRequired') {
                                        disabled = true
                                      }
                                      return (
                                        <TableCell key={j}>
                                          <Checkbox
                                            checked={value}
                                            onChange={() => booleanChange(equipmentsIndex, name, !value)}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            disabled={disabled || isInstallation}
                                          />
                                        </TableCell>
                                      )
                                    }
                                    if (readOnly) {
                                      let displayText = value
                                      if (name === 'EquipmentType') {
                                        displayText = data['EquipmentTypeDisplayText']
                                      }
                                      if (name === 'ExpirationDate') {
                                        displayText = formatDate(value)
                                      }
                                      if (isObj) {
                                        displayText = data[name]?.en || data[name] || ''
                                      }
                                      return (
                                        <TableCell key={j}>
                                          {/* <span style={{ lineHeight: '39px' }}>{displayText}</span> */}
                                          <TextField
                                            name={name}
                                            value={displayText}
                                            variant="outlined"
                                            className={classes.textField}
                                            InputProps={{
                                              readOnly: true,
                                              classes: {
                                                root: classes.cellInputRoot,
                                                input: classes.cellInput,
                                                notchedOutline: classes.readOnly,
                                              },
                                            }}
                                          />
                                        </TableCell>
                                      )
                                    }

                                    if (options.length > 0) {
                                      return (
                                        <TableCell key={j}>
                                          <Autocomplete
                                            disableClearable
                                            onChange={(e, value) => handleChange(e, equipmentsIndex, name, value)}
                                            className={classes.textField}
                                            options={options}
                                            value={value || ''}
                                            getOptionLabel={(option: any) =>
                                              option?.DisplayText?.en || option?.FileName || option
                                            }
                                            classes={{
                                              inputRoot: classes.autocomplete,
                                              paper: classnames({
                                                [classes.option]: !isMax,
                                                [classes.paperMax]: isMax,
                                              }),
                                              listbox: classes.listbox,
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} name={name} variant="outlined" />
                                            )}
                                          />
                                        </TableCell>
                                      )
                                    }
                                    return (
                                      <TableCell key={j}>
                                        <TextField
                                          name={name}
                                          value={value}
                                          variant="outlined"
                                          disabled={disabled}
                                          className={classes.textField}
                                          // autoFocus={intoView && name === 'EquipmentName'}
                                          SelectProps={{
                                            displayEmpty: true,
                                            classes: {
                                              select: classes.select,
                                            },
                                            type: 'search',
                                            MenuProps: {
                                              style: {
                                                maxHeight: 300,
                                              },
                                            },
                                          }}
                                          InputProps={{
                                            classes: {
                                              root: classes.cellInputRoot,
                                              input: classes.cellInput,
                                            },
                                          }}
                                          onChange={(e) => handleChange(e, equipmentsIndex, '', '')}
                                          onBlur={(e) => onBlur(e, equipmentsIndex)}
                                          error={name === 'SignId' && data.SignIdChecked === false}
                                        />
                                      </TableCell>
                                    )
                                  })}
                                </TableRow>
                              )}
                            </Draggable>

                            {!isInstallation && (
                              <QuestionTable
                                FormTemplateFields={FormTemplateFields}
                                open={open as boolean}
                                changeRef={changeRef}
                                equIdx={equipmentsIndex + 1}
                                colSpan={configEquipment.length + 1}
                                action={action}
                                setAction={setAction}
                                PhotoRequired={PhotoRequired}
                                VideoRequired={VideoRequired}
                              />
                            )}
                          </>
                        )
                      })}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isInstallation && (
              <Button className={classes.add} onClick={() => setShowModal({ showModal: true })}>
                <AddCircle style={{ marginRight: 8 }} />
                Add New Equipment/Location
              </Button>
            )}
            {(isAngus || isBE) && (
              <Button className={classes.add} onClick={() => setShowModal({ showAutoClosePM: true })}>
                <img src={Images.iconLink} style={{ marginRight: 8, width: 24 }} />
                <Tooltip title={hasLink ? linkedPMText : ''} placement="top">
                  <Typography
                    style={{
                      width: 320,
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textAlign: 'left',
                    }}
                  >
                    {linkedPMText}
                  </Typography>
                </Tooltip>
              </Button>
            )}
            {roundType === EquipmentFrom.Cleaning && (
              <span className={classes.add} style={{ fontWeight: '500' }}>
                Service Request
                <Switch
                  checked={SREnable}
                  name={'SREnable'}
                  disabled={SRDisabled}
                  onChange={(e, checked) => {
                    changeRef.current.isChanges = true
                    setData((pre) => ({ ...pre, SREnable: checked }))
                  }}
                />
              </span>
            )}
          </Box>

          {showAutoClosePM && (
            <AutoClosePMModal
              onSave={onSave}
              bId={bId}
              isBE={isBE}
              linkedPM={linkedPM}
              eIds={JSON.stringify(disabledArr)}
              onClose={() => setShowModal({ showAutoClosePM: false })}
            />
          )}
          {showModal && (
            <EquipmentModal
              onOk={onAdd}
              onClose={() => setShowModal({ showModal: false })}
              equipmentFrom={roundType}
              disabledArr={disabledArr}
            />
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  )
})

EquipmentTable.displayName = 'EquipmentTable'
export default EquipmentTable
