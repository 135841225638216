import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Typography,
  Button,
  TextField as MuiTextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material'
import { useSelector } from 'react-redux'
import useSetState from '@hooks/useSetState'
import { Datagrid, DateField, List, TextField, RaRecord, FunctionField, Resource } from 'react-admin'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import { DEFAULT_DATE_RANGE_TODAY } from '@/global'
import { SaveAlt, Close } from '@mui/icons-material'
import { downLoadFile } from '@/utils'
import { getDateArchiveFilterInfo } from '@api'
import PanelSelection from '@component/EditPanel/PanelSelection'
import { PanelChoice } from '@/types/panel'
const dateFormat = {
  locales: 'en-US',
  options: { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions,
}

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    padding: '0 24px 18px',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    paddingTop: 32,
    paddingBottom: 16,
    color: '#000',
    fontSize: 32,
    fontWeight: 500,
    borderBottom: '1px solid #BCBCBC',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 24px',
  },
  pdfTitle: {
    flex: 1,
    color: '#211F1F',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '30px',
  },
}))

const DataArchive = () => {
  const classes = useStyles()
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const [params, setData] = useSetState({
    gId: selectedGroupId,
    bId: selectedPropertyId,
    range: DEFAULT_DATE_RANGE_TODAY,
    search: '',
    type: '',
    category: '',
    st: '',
  })
  const [filter, setFilter] = useState<{ categories: PanelChoice[]; types: PanelChoice[] }>()
  const { categories, types } = filter || {}
  const { bId, gId, range, search, category, type, st } = params
  const timerRef = useRef<NodeJS.Timeout>()
  const itemRef = useRef<Record<string, any>>({})
  const getData = useCallback(async () => {
    const result = await getDateArchiveFilterInfo({ gId, bId })
    // @ts-ignore
    const categories = Object.keys(result?.Category || {}).map((item) => ({ label: item, value: item } as PanelChoice))
    // @ts-ignore
    const types = Object.keys(result?.Type || {}).map((item) => ({ label: item, value: item } as PanelChoice))
    setFilter({ types, categories })
  }, [gId, bId])
  const onChangeType = useCallback(
    (item) => {
      setData?.({ type: item.value })
    },
    [setData]
  )
  const onChangeCategory = useCallback(
    (item) => {
      setData?.({ category: item.value })
    },
    [setData]
  )
  const onSearchChange = (e) => {
    const value = e.target.value
    setData({ search: value })
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setData({ st: value })
    }, 3000)
  }
  useEffect(() => {
    getData()
  }, [getData])
  return (
    <div className={classes.paper}>
      <Box className={classes.header}>
        <span style={{ flex: 1 }} />
        <MuiTextField
          value={search}
          onChange={(e) => onSearchChange(e)}
          label="Report Name"
          variant="outlined"
          sx={{ width: '240px', marginRight: '16px' }}
        />
        <PanelSelection
          height={56}
          loading={!categories}
          label="Category"
          width={'240px'}
          sx={{ marginRight: '16px' }}
          value={categories?.find((item: PanelChoice) => item.value === category)}
          choices={categories || []}
          onChange={onChangeCategory}
          getOptionLabel={(option: PanelChoice) => option?.label || ''}
        />
        <PanelSelection
          height={56}
          label="Type"
          loading={!types}
          width={'240px'}
          sx={{ marginRight: '16px' }}
          value={types?.find((item: PanelChoice) => item.value === type)}
          choices={types || []}
          onChange={onChangeType}
          getOptionLabel={(option: PanelChoice) => option?.label || ''}
        />
        <CustomDateRange
          style={{ width: '240px' }}
          value={range}
          onChange={(value) => setData({ range: value })}
          label="Date filter"
        />
      </Box>

      <List
        filter={{ gId, bId, st, type, category, startDate: range[0]?.valueOf(), endDate: range[1]?.valueOf() }}
        perPage={50}
        actions={false}
        exporter={false}
        empty={false}
        sort={{ field: 'SubmitDate', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false} optimized>
          <DateField source="CompleteDate" label="Date Complete" {...dateFormat} />
          <TextField source="ReportName" label="Report Name" />
          <TextField source="Category" label="Category" />
          <TextField source="Type" label="Type" />
          <FunctionField
            label="Download"
            sortable={false}
            align="center"
            render={(record: RaRecord) => {
              if (!record?.FileUrl) return ''
              return (
                <SaveAlt
                  onClick={() => downLoadFile({ url: record?.FileUrl })}
                  htmlColor="#466CB5"
                  sx={{ marginLeft: '24px', cursor: 'pointer' }}
                />
              )
            }}
          />
        </Datagrid>
      </List>
    </div>
  )
}
const DataArchiveReport = () => <Resource name="dataArchive" key="dataArchive" list={DataArchive} />

export default DataArchiveReport
