import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { GoogleMap, LoadScript, OverlayView, Polyline } from '@react-google-maps/api'
import { makeStyles } from '@mui/styles'
import PicturePreview from '@component/Modal/PicturePreview'
const url = 'https://activate-dev-env.s3.us-east-2.amazonaws.com/static/photos_with_coordinates/'

const Photos = [
  {
    name: 'IMG_9633.jpeg',
    lat: 41.87978,
    lng: -87.6372,
  },
  {
    name: 'IMG_9634.jpeg',
    lat: 41.87992,
    lng: -87.63705,
  },
  {
    name: 'IMG_9635.jpeg',
    lat: 41.87991,
    lng: -87.63696,
  },
  {
    name: 'IMG_9636.jpeg',
    lat: 41.88001,
    lng: -87.63687,
  },
  {
    name: 'IMG_9637.jpeg',
    lat: 41.88042,
    lng: -87.6368,
  },
  {
    name: 'IMG_9638.jpeg',
    lat: 41.8805,
    lng: -87.63685,
  },
  {
    name: 'IMG_9639.jpeg',
    lat: 41.88056,
    lng: -87.63679,
  },
  {
    name: 'IMG_9640.jpeg',
    lat: 41.88059,
    lng: -87.63677,
  },
  {
    name: 'IMG_9641.jpeg',
    lat: 41.88061,
    lng: -87.6367,
  },
  {
    name: 'IMG_9642.jpeg',
    lat: 41.88058,
    lng: -87.63667,
  },
  {
    name: 'IMG_9643.jpeg',
    lat: 41.88057,
    lng: -87.63667,
  },
]
const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -96,
    cursor: 'pointer',
    filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.10))',
  },
  content: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: 96,
    height: 96,
    borderRadius: 8,
    background: '#ffffff',
    padding: 8,
  },
  tr: {
    marginTop: -10,
    border: '20px solid transparent',
    borderTopColor: '#ffffff',
    borderBottom: 0,
  },
  img: {
    width: 80,
    height: 80,
    objectFit: 'cover',
    borderRadius: 5,
  },
  countWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 28,
    height: 28,
    borderRadius: 14,
    border: '3px solid white',
    backgroundColor: '#466CB5',
  },
  count: {
    fontSize: 14,
    fontWeight: 700,
    color: 'white',
  },
}))
const OverlayViewComponent = ({ overlays, map }) => {
  useEffect(() => {
    if (map && overlays.length > 0) {
      const bounds = new window.google.maps.LatLngBounds()

      // 将所有 Overlay 的位置加入 bounds
      overlays.forEach((overlay) => {
        if (overlay.position) {
          bounds.extend(overlay.position)
        }
      })

      // 调整地图视图以适应 bounds
      map.fitBounds(bounds)
    }
  }, [map, overlays])

  return null
}

const ResearchTest: React.FC = () => {
  const classes = useStyles()
  const position = {
    lat: Photos[0].lat,
    lng: Photos[0].lng,
  }
  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds()
    Photos.forEach((marker) => bounds.extend(marker))
    map.fitBounds(bounds)
  }
  const polylineOptions = {
    strokeColor: '#91B7DB',
    strokeOpacity: 1.0,
    strokeWeight: 2,
    icons: [
      {
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 4,
        },
        offset: '0',
        repeat: '20px',
      },
    ],
  }
  return (
    <Box>
      <LoadScript googleMapsApiKey="AIzaSyBzyaT5Rk22FbDRNvMWDETsKY9Pk1AruM4">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100vh', marginBottom: '24px' }}
          zoom={20}
          center={position}
          // onLoad={onLoad}
          options={{ disableDefaultUI: true }}
        >
          {Photos.map((item) => {
            const position = {
              lat: item.lat,
              lng: item.lng,
            }
            return (
              <OverlayView
                // bounds={new window.google.maps.LatLngBounds(position)}
                key={item.name}
                position={position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Box
                  className={classes.box}
                  onClick={(e) => {
                    e.stopPropagation()
                    PicturePreview.show({
                      urls: [url + item.name],
                    })
                  }}
                >
                  <Box className={classes.content}>
                    <img src={url + item.name} className={classes.img} alt="" />
                    <Box className={classes.countWrap}>
                      <Typography className={classes.count}>1</Typography>
                    </Box>
                  </Box>
                  <Box className={classes.tr} />
                </Box>
              </OverlayView>
            )
          })}
          <Polyline path={Photos.map((item) => ({ lat: item.lat, lng: item.lng }))} options={polylineOptions} />
        </GoogleMap>
      </LoadScript>
    </Box>
  )
}
export default ResearchTest
