import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => {
  return {
    content: {
      textAlign: 'right',
      alignSelf: 'flex-end',
      width: '100%',
    },
  }
})

interface Props {
  showSave?: boolean
  disabledSave?: boolean
  hiddenSave?: boolean
  hiddenCancel?: boolean
  onCancel?: () => void
  onSave?: () => void
  onEdit?: () => void
}
const EditAndSave: React.FC<Props> = ({
  showSave,
  onCancel,
  onEdit,
  onSave,
  hiddenCancel,
  hiddenSave,
  disabledSave,
}) => {
  const classes = useStyles()
  const onClick = () => {
    onEdit?.()
  }
  const onClickCancel = () => {
    onCancel?.()
  }
  return (
    <Box className={classes.content}>
      {showSave ? (
        <>
          {!hiddenCancel && (
            <Button variant="outlined" color="primary" style={{ marginRight: 16, width: 90 }} onClick={onClickCancel}>
              Cancel
            </Button>
          )}
          {!hiddenSave && (
            <Button disabled={disabledSave} variant="contained" color="primary" style={{ width: 90 }} onClick={onSave}>
              Save
            </Button>
          )}
        </>
      ) : (
        <Button variant="contained" color="primary" style={{ width: 90 }} onClick={onClick}>
          Edit
        </Button>
      )}
    </Box>
  )
}

export default EditAndSave
