import React, { useMemo, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ContentSurveyWrap from '@pages/survey/ContentSurveyWrap'

const ContentSurvey: React.FC = () => {
  const location = useLocation()
  const { pathname } = location
  const { user } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const { bId: bIdT = '', gId: gIdT, builderId: builderIdT } = useParams<Record<string, string>>()
  const { gId, bId, isResponses, builderId } = useMemo(() => location.state || {}, [location.state])
  useEffect(() => {
    if (!user) {
      navigate('/login', {
        replace: true,
        state: {
          nextPathname: pathname,
          redirectToNextPath: true,
        },
      })
    }
  }, [navigate, user, pathname])
  console.log('builderIdT', builderIdT)
  return (
    <ContentSurveyWrap
      hiddenMenu
      disabledBack
      gId={gIdT || gId}
      bId={bIdT || bId}
      builderId={builderIdT || builderId}
      isResponses={isResponses}
    />
  )
}

export default ContentSurvey
