import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { useCallback } from 'react'
import { LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateRange, DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { DEFAULT_DATE_RANGE_LAST_YEAR, DEFAULT_DATE_RANGE_TODAY, DEFAULT_DATE_RANGE_YTD } from '@/global'

const useStyles = makeStyles(() => ({
  root: {
    width: 223,
  },
  input: {
    color: '#211F1F',
    borderRadius: 4,
    fontSize: 20,
    height: 56,
    paddingRight: '9px !important',
  },
}))

interface Props {
  value?: DateRange<Dayjs>
  options?: any[]
  onChange?: (value: DateRange<Dayjs>) => void
  disabled?: boolean
  defaultValue?: any
  label?: string
  className?: string
  style?: React.CSSProperties
}

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Today',
    getValue: () => {
      return DEFAULT_DATE_RANGE_TODAY
    },
  },
  {
    label: 'WTD',
    getValue: () => {
      const today = dayjs()
      return [today.startOf('week'), today]
    },
  },
  {
    label: 'MTD',
    getValue: () => {
      const today = dayjs()
      return [today.startOf('month'), today]
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(7, 'day'), today]
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(30, 'day'), today]
    },
  },
  {
    label: 'Last 60 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(60, 'day'), today]
    },
  },
  {
    label: 'Last 90 Days',
    getValue: () => {
      const today = dayjs()
      return [today.subtract(90, 'day'), today]
    },
  },
  {
    label: 'Last 12 Months',
    getValue: () => {
      return DEFAULT_DATE_RANGE_LAST_YEAR
    },
  },
  {
    label: 'YTD',
    getValue: () => {
      return DEFAULT_DATE_RANGE_YTD
    },
  },
]

const CustomDateRange: React.FC<Props> = ({
  label = 'Date Range',
  value,
  disabled,
  onChange,
  defaultValue,
  className,
  style,
}) => {
  const classes = useStyles()

  function getElementByText() {
    const elements1 = document.getElementsByClassName('MuiDateRangeCalendar-root')
    const elements = elements1?.[0]?.childNodes || []
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent === 'MUI X Missing license key') {
        return elements[i]
      }
    }
    return null
  }

  const onChangeDate = useCallback(
    (date: DateRange<Dayjs>) => {
      if (date.length === 2 && date[0] && date[1]) {
        date[0] = date[0].hour(0).minute(0).second(0)
        date[1] = date[1].hour(23).minute(59).second(59)
        onChange?.(date)
      }
    },
    [onChange]
  )
  const onOpen = () => {
    setTimeout(() => {
      // remove Licensing
      const list = getElementByText()
      if (list) {
        // @ts-ignore
        list.style.display = 'none'
      }
    }, 0)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker<Dayjs>
        onChange={onChangeDate}
        value={value}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        sx={{ minWidth: 220, ...(style || {}) }}
        defaultValue={defaultValue}
        disableFuture
        calendars={1}
        onOpen={onOpen}
        slots={{ field: SingleInputDateRangeField }}
        name="allowedRange"
      />
    </LocalizationProvider>
  )
}

export default CustomDateRange
