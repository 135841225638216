import React, { useEffect, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Box, Typography, Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material'
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import { makeStyles } from '@mui/styles'
import useSetState from '@/hooks/useSetState'
import { generateQrStyle } from '@api'
import { EYE_FRAME_SHAPE, EYE_BALL_SHAPE, BODY_SHAPE_MAP } from '@/qrcodes/QRCodeConfig'
import EditAndSave from '@component/Button/EditAndSave'
import RichTextEdit from '@component/Form/richText'
import UploadFile from '@component/Form/UploadFile'
import { useSelector } from 'react-redux'

export const BRANDING_BASE_NAME = {
  LogoFilename: '',
  FaviconFilename: '',
  BackgroundColor: '#FFF',
  FillColor: '#000000',
  BodyShape: 'circle',
  EyeFrame: 'frame2',
  EyeBall: 'ball0',
  EyeColor: '#000000',
}
const BRANDING_NAME = {
  ...BRANDING_BASE_NAME,
  Source: 'Property',
  QRStyleUrl: '',
  HtmlPage: { Footer: '', Header: '' },
  HubHeader: '',
}
type StateKey = keyof typeof BRANDING_NAME
const SHAPE_CONFIG = [
  { label: 'Body Shape', key: 'BodyShape', option: BODY_SHAPE_MAP },
  { label: 'Eye Frame', key: 'EyeFrame', option: EYE_FRAME_SHAPE },
  { label: 'Eye Ball Shape', key: 'EyeBall', option: EYE_BALL_SHAPE },
]

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
      position: 'relative',
    },
    color: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    label: {
      marginBottom: 24,
      fontWeight: 600,
      width: 200,
    },
    preview: {
      marginLeft: 20,
    },
    previewButton: {
      width: 161,
      textAlign: 'center',
      color: '#386BBF',
      marginTop: 24,
      textTransform: 'none',
    },
    emptyImg: {
      width: 161,
      height: 161,
      textAlign: 'center',
      lineHeight: '161px',
    },
    upload: {
      cursor: 'pointer',
      fontSize: 14,
      backgroundColor: '#386BBF',
      padding: '8px 16px',
      color: '#fff',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
    },
    img: {
      width: 300,
      marginRight: 16,
      border: '1px solid #BCBCBC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '6px 16px',
      borderRadius: 4,
    },
    box: {
      display: 'flex',
      width: '800px',
      flexWrap: 'wrap',
    },
    formControl: {
      flex: 1,
      minWidth: '40%',
      marginBottom: 24,
      '&:nth-child(2n-1)': {
        marginRight: '24px',
      },
    },
    select: {
      padding: '12px 16px 4px',
    },
    itemWrap: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      paddingBottom: '24px',
      marginBottom: '24px',
      borderBottom: '0.5px solid #BCBCBC',
    },
    htmlContainer: {
      display: 'flex',
      gap: 24,
      marginBottom: 24,
      paddingBottom: 24,
      borderBottom: '0.5px solid #BCBCBC',
    },
    htmlItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    edit: {
      marginTop: 16,
    },
  }
})

interface Props {
  gId: string
  informationRef: React.MutableRefObject<any>
  disabled?: boolean
  isEdit?: boolean
  editData?: any
  onSave: () => void
  onBack?: () => void
  onChangeData?: (state: any) => void
  hiddenPreview?: boolean
  hiddenCancel?: boolean
  showSave?: boolean
}

const PropertyBranding = (props: Props) => {
  const {
    gId,
    informationRef,
    isEdit,
    editData,
    onSave,
    onBack,
    disabled,
    hiddenPreview,
    onChangeData,
    hiddenCancel,
    showSave,
  } = props
  const classes = useStyles()
  const { user } = useSelector((state) => state.profile)
  const cacheState = useRef<any>({ ...BRANDING_NAME })
  const [state, setState] = useSetState({ ...BRANDING_NAME })
  const [onlyView, setOnlyView] = useState(isEdit && !showSave)

  const getData = useCallback(async () => {
    return cacheState.current
  }, [])
  const { LogoFilename, FaviconFilename, QRStyleUrl, HubHeader } = state
  const onCancel = () => {
    if (onBack) {
      onBack()
      return
    }
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const colorChange = (newValue: string, key: string) => {
    if (matchIsValidColor(newValue)) {
      // @ts-ignore
      setState({ [key]: newValue })
    }
  }

  const onPreview = () => {
    generateQrStyle({
      ...state,
      BuildingId: editData?.BuildingId || '',
      GroupId: gId,
    }).then((res: any) => {
      setState({ QRStyleUrl: `${res?.ImageUrl}?${new Date().getTime()}` })
    })
  }

  useImperativeHandle(
    informationRef,
    () => ({
      ...informationRef.current,
      getData,
    }),
    [getData]
  )

  useEffect(() => {
    if (isEdit && editData) {
      console.log('222')
      const state = { ...BRANDING_NAME }
      for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key) && editData[key]) {
          state[key as StateKey] = editData[key]
        }
      }
      setState({ ...state })
    }
  }, [isEdit, editData, BRANDING_NAME])
  useEffect(() => {
    cacheState.current = state
    onChangeData?.(state)
  }, [state, onChangeData])
  return (
    <Box className={`${classes.content}`}>
      <div style={{ flexDirection: 'column', alignItems: 'flex-start' }} className={classes.itemWrap}>
        <Typography className={classes.label}>Header Logo</Typography>
        <UploadFile
          sx={{ display: 'flex', width: '800px', marginBottom: '24px' }}
          filePath={`${gId}/${user?.acId}/LogoFilename`}
          value={LogoFilename}
          disabled={disabled || onlyView}
          uploadText="UPLOAD"
          reUploadText="RE-UPLOAD"
          onChange={(file) => setState({ LogoFilename: file })}
          displayMode="preview"
          accept=".png, .jpg, .jpeg"
        />
      </div>
      <div style={{ paddingBottom: 0 }} className={classes.itemWrap}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.label}>QR Logo</Typography>
          <UploadFile
            disabled={disabled || onlyView}
            sx={{ display: 'flex', width: '800px', marginBottom: '24px' }}
            filePath={`${gId}/${user?.acId}/FaviconFilename`}
            value={FaviconFilename}
            uploadText="UPLOAD"
            reUploadText="RE-UPLOAD"
            onChange={(file) => setState({ FaviconFilename: file })}
            displayMode="preview"
            accept=".png, .jpg, .jpeg"
          />
          <Box className={classes.box}>
            <MuiColorInput
              value={state.BackgroundColor}
              format="hex"
              onChange={(newValue) => colorChange(newValue, 'BackgroundColor')}
              label="Background Color"
              fallbackValue={BRANDING_NAME.BackgroundColor}
              isAlphaHidden
              className={classes.formControl}
              disabled={disabled || onlyView}
            />
            <MuiColorInput
              value={state.FillColor}
              format="hex"
              onChange={(newValue) => colorChange(newValue, 'FillColor')}
              label="Body Fill"
              fallbackValue={BRANDING_NAME.FillColor}
              isAlphaHidden
              className={classes.formControl}
              disabled={disabled || onlyView}
            />
            {SHAPE_CONFIG.map((v, i) => {
              const { label, key, option } = v
              return (
                <FormControl className={classes.formControl} key={i}>
                  <InputLabel>{label}</InputLabel>
                  <Select
                    // @ts-ignore
                    onChange={(e) => setState({ [key]: e.target.value as string })}
                    value={state[key as StateKey]}
                    label={label}
                    fullWidth
                    classes={{ select: classes.select }}
                    disabled={disabled || onlyView}
                  >
                    {option.map((name) => {
                      return (
                        <MenuItem key={name} value={name}>
                          <img src={require(`../assets/images/qrcodestyle/${name}.png`)} alt={name} height={33} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )
            })}
            <MuiColorInput
              value={state.EyeColor}
              format="hex"
              onChange={(newValue) => colorChange(newValue, 'EyeColor')}
              label="Eye Color"
              fallbackValue={BRANDING_NAME.EyeColor}
              isAlphaHidden
              className={classes.formControl}
              disabled={disabled || onlyView}
            />
          </Box>
        </div>
        {!hiddenPreview && (
          <Box className={classes.preview}>
            <Box>
              {QRStyleUrl ? (
                <img src={QRStyleUrl} width={161} height={161} alt="QRStyleUrl" />
              ) : (
                <Box className={classes.emptyImg}>Not Available</Box>
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              className={classes.previewButton}
              onClick={onPreview}
              disabled={disabled || onlyView}
            >
              Preview
            </Button>
          </Box>
        )}
      </div>
      <Typography className={classes.label}>HTML Header & Footer</Typography>
      <Box className={classes.htmlContainer}>
        <Box className={classes.htmlItem}>
          <Typography>Header</Typography>
          <RichTextEdit
            key={disabled || onlyView ? 'RichTextEdit' : 'RichTextEdit2'}
            className={classes.edit}
            htmlContent={state.HtmlPage?.Header || ''}
            onSubmit={(value) => {
              setState({ HtmlPage: Object.assign({}, state.HtmlPage, { Header: value }) })
            }}
            editorStyle={{ height: '300px' }}
            disabledEdit={disabled || onlyView}
          />
        </Box>
        <Box className={classes.htmlItem}>
          <Typography>Footer</Typography>
          <RichTextEdit
            key={disabled || onlyView ? 'RichTextEdit' : 'RichTextEdit2'}
            className={classes.edit}
            htmlContent={state.HtmlPage?.Footer || ''}
            onSubmit={(value) => {
              setState({ HtmlPage: Object.assign({}, state.HtmlPage, { Footer: value }) })
            }}
            editorStyle={{ height: '300px' }}
            disabledEdit={disabled || onlyView}
          />
        </Box>
      </Box>
      <Typography className={classes.label}>Hub Header</Typography>
      <UploadFile
        filePath={`${gId}/${user?.acId}/HubHeader`}
        value={HubHeader}
        uploadText="UPLOAD"
        reUploadText="RE-UPLOAD"
        onChange={(file) => setState({ HubHeader: file })}
        displayMode="preview"
        accept="image/*"
        disabled={disabled || onlyView}
      />
      <EditAndSave
        showSave={showSave || !onlyView}
        onSave={onSave}
        onCancel={onCancel}
        hiddenCancel={hiddenCancel}
        onEdit={onEdit}
      />
    </Box>
  )
}
export default React.memo(PropertyBranding)
