import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Button, Modal, TextField, Checkbox, Autocomplete } from '@mui/material'
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@mui/icons-material'
import useSetState from '@hooks/useSetState'
import { getWorkOrderTemplates } from '@api'

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
    padding: 24,
    maxHeight: '80%',
    backgroundColor: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderRadius: 6,
  },
  tag: {
    position: 'absolute',
    overflow: 'hidden',
    left: 16,
    right: 40,
    color: '#4D4D4D',
    zIndex: -1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))
interface Props {
  onClose: () => void
  onSave: (data: WorkOrderTemplateITF[]) => void
  title?: string
  bId?: string
  eIds?: string
  isBE?: boolean
  linkedPM?: WorkOrderTemplateITF[]
}
export interface WorkOrderTemplateITF {
  Title: string
  EquipmentId: string
  ScheduleId: string
}
const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AutoClosePMModal: React.FC<Props> = React.memo(({ onClose, onSave, bId, eIds, linkedPM, isBE }) => {
  const classes = useStyles()
  const [{ templates, selectedTemplates }, setData] = useSetState<{
    templates: WorkOrderTemplateITF[]
    selectedTemplates?: WorkOrderTemplateITF[]
  }>({ templates: [], selectedTemplates: linkedPM || [] })
  const onSaveClick = () => {
    onSave(selectedTemplates || [])
    onClose?.()
  }
  const getData = useCallback(() => {
    const params = { bId, eIds }
    if (isBE) {
      Object.assign(params, { type: 'BEPreMaintenance' })
    }
    getWorkOrderTemplates<{ Templates: WorkOrderTemplateITF[] }>(params).then((res) => {
      setData({ templates: res.Templates })
    })
  }, [bId, eIds, setData])
  useEffect(() => {
    getData()
  }, [getData])
  return (
    <Modal open={true} disableEscapeKeyDown>
      <Box className={classes.modal}>
        <Typography variant="h5" sx={{ fontSize: '16px', marginBottom: '24px' }}>
          Link Round to PM
        </Typography>
        <Autocomplete
          disableClearable
          onChange={(_, newValue) => setData({ selectedTemplates: newValue })}
          options={templates}
          multiple
          disableCloseOnSelect
          sx={{ marginBottom: '24px' }}
          value={selectedTemplates}
          getOptionLabel={(option: any) => option?.Title}
          isOptionEqualToValue={(option, value) =>
            option?.ScheduleId === value?.ScheduleId && option?.Title === value?.Title
          }
          renderInput={(params) => <TextField {...params} variant="outlined" label="PM" />}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option?.Title}
              </li>
            )
          }}
          renderTags={(value) => {
            if (value?.length > 0) return <div className={classes.tag}>{value.map((v) => v?.Title)?.join(',')}</div>
            return null
          }}
        />
        <Box sx={{ textAlign: 'right', marginTop: '16px' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSaveClick} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default AutoClosePMModal
