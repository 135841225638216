import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import EditAndSave from '@component/Button/EditAndSave'
import { AddCircle } from '@mui/icons-material'
import { getResidentIndex, saveResidentIndex } from '@api'
import { PropertyResidentFieldITF } from '@/types/entity'
import CustomTextField from '@component/Form/CustomTextField'
import PanelSelection from '@component/EditPanel/PanelSelection'
import Images from '@assets/images'
import { PanelChoice } from '@/types/panel'
import SnackBar from '@component/SnackBar'

const useStyles = makeStyles(() => {
  return {
    container: {
      backgroundColor: '#fff',
      position: 'relative',
      fontFamily: 'Roboto',
    },
    bottom: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    add: {
      marginLeft: 10,
      whiteSpace: 'nowrap',
      padding: '2px 10px 2px 0px',
      fontSize: 14,
      color: '#466CB5',
    },
    item: {
      gap: 24,
      marginBottom: 24,
      display: 'flex',
      alignItems: 'center',
    },
    flx: {
      flex: 1,
    },
    sort: {
      fontSize: 20,
      fontWeight: 500,
      color: '#000',
    },
    deleteIcon: {},
  }
})

interface Props {
  disabled?: boolean
  isEdit?: boolean
  onBack?: () => void
  gId: string
  bId: string
}
const FIELD_TYPE = [
  { value: 'Text', label: 'Text' },
  { value: 'TextVideo', label: 'Text, Video' },
  { value: 'Video', label: 'Video' },
]
const PropertyResidentIndex: React.FC<Props> = ({ isEdit, gId, bId, disabled }) => {
  const classes = useStyles()
  const [onlyView, setOnlyView] = useState(isEdit)
  const [id, setId] = useState<string>()
  const [fields, setFields] = useState<PropertyResidentFieldITF[]>([])
  const disabledSave = useMemo(() => {
    return !!fields.find((item) => {
      if (Array.isArray(item.FileUrls)) item.FileUrls = ''
      return !item.Name || !item.Type
    })
  }, [fields])
  const getData = useCallback(() => {
    getResidentIndex<{ acId: string; Fields: PropertyResidentFieldITF[] }>({ IsTemplate: true, bId, gId }).then(
      (res) => {
        setFields(res.Fields)
        setId(res.acId)
      }
    )
  }, [bId, gId])
  useEffect(() => {
    getData()
  }, [getData])
  const onClickBack = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const onSave = () => {
    if (!id) return
    saveResidentIndex({ ResidentIndexId: id, Fields: fields }).then((res) => {
      SnackBar({ msg: 'Successfully saved!', type: 'success' })
    })
  }
  const onAdd = () => {
    const item = {} as PropertyResidentFieldITF
    fields.push(item)
    setFields([...fields])
  }
  const onClickDelete = (index: number) => {
    fields.splice(index, 1)
    setFields([...fields])
  }
  const onInput = (name: string, value: string, index: number) => {
    const item = fields[index] || {}
    item[name] = value
    fields.splice(index, 1, item)
    setFields([...fields])
  }
  return (
    <Box className={classes.container}>
      {fields?.map((item, index) => {
        const { Name, Type } = item
        return (
          <Box key={index} className={classes.item}>
            <Typography className={classes.sort}>{index + 1}</Typography>
            <CustomTextField
              value={Name}
              disabled={onlyView}
              className={classes.flx}
              noMargin
              onInputChange={(value) => onInput('Name', value as string, index)}
              variant="outlined"
              label="Field Name"
            />
            <PanelSelection
              disabled={onlyView}
              label="Field Type"
              className={classes.flx}
              height={56}
              width="100%"
              choices={FIELD_TYPE}
              value={FIELD_TYPE?.find((item: PanelChoice) => item.value === Type)}
              onChange={(item) => onInput('Type', item?.value as string, index)}
            />
            <IconButton onClick={() => onClickDelete(index)} disabled={onlyView}>
              <img src={Images.iconDelete} className={classes.deleteIcon} alt="" />
            </IconButton>
          </Box>
        )
      })}
      <Box className={classes.bottom}>
        <Button aria-label="add" className={classes.add} disabled={onlyView} onClick={onAdd}>
          <AddCircle color="primary" style={{ marginRight: 10 }} />
          Add New Fields
        </Button>
        <EditAndSave
          disabledSave={disabledSave}
          showSave={!onlyView}
          onCancel={onClickBack}
          onEdit={onEdit}
          onSave={onSave}
        />
      </Box>
    </Box>
  )
}

export default React.memo(PropertyResidentIndex)
