import React, { useCallback, useEffect } from 'react'
import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ContentSurveyWrap from '@pages/survey/ContentSurveyWrap'
import useSetState from '@hooks/useSetState'
import classnames from 'classnames'
import TitleWrap from '@pages/survey/components/TitleWrap'
import { AccountSearch } from '@/dashboard/AdminSearch'
import { PropertySearch } from '@/dashboard/PropertySearchBar'
import { GroupITF } from '@/types/group'
import { IEntity } from '@/types'
import PropertyInfoEdit from './components/PropertyInfoEdit'
import CustomTextField from '@component/Form/CustomTextField'
import AdditionalSurvey from './components/AdditionalSurvey'
import { getSurveyBuilder, saveProductQuestions } from '@api'
import { ArchorTenantITF, ConnectivityITF, ContentSurveyITF } from '@/types/entity'
import uuid from 'react-uuid'
import SnackBar from '@component/SnackBar'
import { useParams } from 'react-router-dom'
import { copyText } from '@/utils'
import CONFIG from '@constant/config'
import { Info } from '@mui/icons-material'
import UploadFile from '@component/Form/UploadFile'

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      margin: '0 24px 24px',
      padding: 24,
      borderRadius: 16,
      position: 'relative',
      fontFamily: 'Roboto',
    },
    content: {
      display: 'flex',
    },
    left: {
      display: 'flex',
      flexDirection: 'column',
      width: 827,
      marginTop: 24,
      borderRadius: 16,
      padding: '12px 24px 24px',
      backgroundColor: '#F7F7F7',
      textTransform: 'none',
    },
    propertyInfo: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '700px !important',
    },
    account: {
      paddingTop: 0,
      paddingBottom: 24,
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      width: 600,
      marginLeft: 24,
      marginTop: 24,
    },
    empty: {
      marginTop: 24,
      minHeight: 400,
      textAlign: 'center',
      fontSize: 32,
      paddingTop: 200,
      fontWeight: 600,
      padding: '16px 24px',
      backgroundColor: '#F7F7F7',
    },
    textField: {
      height: 56,
      '&[class*=MuiOutlinedInput-root]': {
        padding: '9px 9px',
        fontSize: 16,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 16,
      },
    },
    textFieldMar: {
      marginTop: 24,
    },
    linkBtn: {
      alignSelf: 'flex-end',
      marginTop: 24,
    },
    builder: {
      fontSize: 24,
      fontWeight: 600,
    },
    link: {
      display: 'flex',
      padding: '12px 16px',
      borderRadius: '8px',
      background: '#F0F6FC',
      marginTop: 24,
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '14px',
      lineHeight: '24px',
    },
    copyLink: {
      color: '#466CB5',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    switch: {
      alignSelf: 'flex-start',
      marginLeft: 0,
    },
  }
})
const SurveyBuilder: React.FC = () => {
  const classes = useStyles()
  const { bId: bIdT = '', gId: gIdT, builderId: builderIdT } = useParams<Record<string, string>>()
  const [{ gId = gIdT, bId = bIdT, info, builderId = builderIdT }, setData] = useSetState<{
    bId?: string
    gId?: string
    builderId?: string
    info?: ContentSurveyITF
  }>({})
  const showLink = info?.BuilderStatus === 'Completed'
  const { SurveyName, Products, HelperText } = info || {}
  const { Text: HelperTextStr, File: HelperTextFile, Enable: HelperTextEnable } = HelperText || {}
  const link = `${CONFIG.adminHost}/surveyWelcome/contentSurvey/${bId}/${builderId}`
  const onChangeAccount = useCallback(
    (account: GroupITF) => {
      setData({ info: undefined, gId: account.acId, bId: '' })
    },
    [setData]
  )

  const onChangeProperty = useCallback(
    (property: IEntity) => {
      setData({ info: undefined, bId: property.id })
    },
    [setData]
  )
  const onChangeInfo = useCallback(
    (data: ContentSurveyITF) => {
      setData((pre) => {
        const dataNext = { ...pre, info: Object.assign({}, pre.info, data) }
        if (data.BuilderId) Object.assign(dataNext, { builderId: data.BuilderId })
        return dataNext
      })
    },
    [setData]
  )
  const onGenerate = useCallback(() => {
    const keys = [
      'BuildingId',
      'GroupId',
      'SurveyName',
      'EnableBasicPropertyInfo',
      'EnableBranding',
      'EnableConnectivities',
      'EnableContacts',
      'EnableFloorsUnits',
      'EnableManagementInfo',
      'EnablePhysicalSpaces',
      'EnablePortfolioAccount',
      'ProductQuestions',
      'HelperText',
    ]
    if (!info) return
    const params = keys.reduce((pre, key) => {
      return Object.assign(pre, { [key]: info?.[key] })
    }, {})
    Object.assign(params, { BuilderId: builderId })
    saveProductQuestions(params).then((res: any) => {
      copyText(`${CONFIG.adminHost}/surveyWelcome/contentSurvey/${bId}/${builderId}`)
      SnackBar({ msg: 'Successfully generated！', type: 'success' })
      setData({ info: { ...info, BuilderStatus: res?.Status } })
    })
  }, [bId, gId, info, builderId])
  const hasSelect = !!gId && !!bId
  const getData = useCallback(async () => {
    const result = await getSurveyBuilder<ContentSurveyITF>({ gId, bId, builderId: builderIdT })

    if ((result.PhysicalSpaces || []).length === 0) {
      const PhysicalSpaces = result.PhysicalSpaces.map((item) => ({
        ...item,
        FrontItemKey: uuid(),
      }))
      result.PhysicalSpaces = PhysicalSpaces || []
    }
    if ((result?.TenantInfo?.ArchorTenants || []).length === 0) {
      result.TenantInfo = { ArchorTenants: [{}] as unknown as ArchorTenantITF[] }
    }
    if ((result?.Connectivities || []).length === 0) {
      result.Connectivities = [{}] as unknown as ConnectivityITF[]
    }
    const managementInfo = {
      Workflow: [],
      HaveApp: '',
      AppPlatform: '',
      Additional: '',
    }
    const managementInfoProgress = {
      Numerator: 0,
      Denominator: 2,
    }
    result.ManagementInfo = { ...managementInfo, ...result?.ManagementInfo }
    result.ManagementInfoProgress = { ...managementInfoProgress, ...result?.ManagementInfoProgress }
    const data = { info: result }
    result.BuilderId && Object.assign(data, { builderId: result.BuilderId })
    setData(data)
  }, [bId, gId, builderIdT])
  useEffect(() => {
    if (!gId || !bId) return
    getData()
  }, [getData])
  const disabled = !SurveyName || (Products || []).length === 0
  return (
    <Box className={classes.root}>
      <Typography className={classes.builder}>Survey Builder</Typography>
      <Box className={classes.content}>
        <Box className={classes.left}>
          {hasSelect && info ? (
            <ContentSurveyWrap
              title="Property Information"
              gId={gId}
              bId={bId}
              data={info}
              isBuilder
              disabledBack
              className={classes.propertyInfo}
            />
          ) : (
            <Typography className={classnames(classes.propertyInfo, classes.empty)}>No data</Typography>
          )}
        </Box>
        <Box className={classes.right}>
          <TitleWrap title="Account And Building" className={classes.account}>
            <AccountSearch
              label="Account"
              onChange={onChangeAccount}
              gId={gId || ''}
              inputRootClass={classes.textField}
              className={classes.textFieldMar}
            />
            <PropertySearch
              label="Building"
              gId={gId || ''}
              bId={bId || ''}
              onChange={onChangeProperty}
              inputRootClass={classes.textField}
              className={classes.textFieldMar}
            />
          </TitleWrap>
          {hasSelect && info && (
            <>
              <TitleWrap title="Property Information">
                <PropertyInfoEdit onChange={onChangeInfo} data={info} />
              </TitleWrap>
              <TitleWrap title="Additional Survey Questions">
                <AdditionalSurvey data={info} gId={gId} bId={bId} builderId={builderId} onChange={onChangeInfo} />
              </TitleWrap>
              <TitleWrap title="Helper Text" noBorder>
                <FormControlLabel
                  className={classes.switch}
                  control={
                    <Switch
                      checked={HelperTextEnable}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        const nextData = Object.assign({}, HelperText, { Enable: checked })
                        onChangeInfo({ HelperText: nextData } as unknown as ContentSurveyITF)
                      }}
                      name="HelperTextEnable"
                    />
                  }
                  label="Helper Text"
                  labelPlacement="start"
                />
                <CustomTextField
                  value={HelperTextStr}
                  multiline
                  variant="outlined"
                  label="Helper Text"
                  disabled={false}
                  sx={{ marginBottom: '24px' }}
                  onInputChange={(value) => {
                    const nextData = Object.assign({}, HelperText, { Text: value })
                    onChangeInfo({ HelperText: nextData } as unknown as ContentSurveyITF)
                  }}
                />
                <UploadFile
                  filePath={`HelperText/${gId}/${bId}`}
                  value={HelperTextFile || ''}
                  uploadText="UPLOAD"
                  disabledInput
                  onChange={(file) => {
                    const nextData = Object.assign({}, HelperText, { File: file })
                    onChangeInfo({ HelperText: nextData } as unknown as ContentSurveyITF)
                  }}
                  accept=".pdf"
                />
              </TitleWrap>
              <TitleWrap title="Delivery" noBorder>
                <CustomTextField
                  value={SurveyName}
                  variant="outlined"
                  label="Name Survey"
                  disabled={false}
                  onInputChange={(value) => {
                    onChangeInfo({ SurveyName: value } as unknown as ContentSurveyITF)
                  }}
                />
              </TitleWrap>
              <Button variant="contained" className={classes.linkBtn} onClick={onGenerate} disabled={disabled}>
                Generate survey and copy link
              </Button>
              {showLink && (
                <Box className={classes.link}>
                  <Info htmlColor="#466CB5" sx={{ marginRight: '8px' }} />
                  <span style={{ flex: 1 }}>{link}</span>
                  <span className={classes.copyLink} onClick={() => copyText(link)}>
                    Copy Link
                  </span>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
export default SurveyBuilder
