import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useTranslate, useLocaleState, useRedirect, usePermissions, useLogout } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { makeStyles } from '@mui/styles'
import { getGroups } from '@api'
import { selectedProperty, selectedGroup, resetReducer } from '@reducer/profile'
import { AppContext } from '../context/AppContext'
import { AddCircle, ExpandMore } from '@mui/icons-material'
import { LangType } from '../types'
import { ACTIVATE_COMPANY_LOGO } from '../global'
import { useLocation } from 'react-router'
import { GroupITF } from '@/types/group'
import { getPermissions } from '@common/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    height: 48,
    backgroundColor: 'white',
    '&[class*=MuiOutlinedInput-root]': {
      padding: '0 9px',
    },
  },
  label: {
    transform: 'translate(14px, 12px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
  inputPosition: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: '#7F8487',
    marginLeft: 20,
    fontWeight: 400,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  logout: {
    position: 'absolute',
    bottom: 40,
    textDecoration: 'underline',
    textAlign: 'center',
  },
}))

interface SearchProps {
  style?: React.CSSProperties
  isBarSearch?: boolean
  onlyShowDefault?: boolean
  onChange?: (group: GroupITF) => void
  className?: string
  inputRootClass?: string
  label?: string
  gId?: string
}

const Search = (props: SearchProps) => {
  const { style = {}, isBarSearch, onlyShowDefault, onChange, className = '', inputRootClass, label, gId } = props
  const { updateGroup } = useContext(AppContext)
  const { selectedGroupId, selectedGroupName } = useSelector((state) => state.profile)
  const groupId = typeof gId === 'undefined' ? selectedGroupId : gId
  const [loading, setLoading] = useState(true)
  const { permissions } = usePermissions()
  const { ManageEntities } = permissions || {}
  const [groups, setGroups] = useState<GroupITF[]>()
  const translate = useTranslate()
  const [locale] = useLocaleState()
  const redirectTo = useRedirect()
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { Groups } = (await getGroups()) as any
        setGroups(Groups)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    fetchData()
  }, [selectedGroupId, isBarSearch])
  if (!groups && !onlyShowDefault && isBarSearch) return null
  const showGroups = onlyShowDefault
    ? [{ GroupName: { en: selectedGroupName, es: selectedGroupName }, acId: selectedGroupId } as unknown as GroupITF]
    : groups
  const option = showGroups?.find((v) => v.acId === groupId) || ''
  const disabledBtn = isBarSearch && showGroups?.length === 1

  return (
    <Autocomplete
      id="free-solo-2-demo"
      disableClearable
      loading={loading}
      disabled={disabledBtn}
      className={className}
      classes={{ inputRoot: `${classes.textField} ${inputRootClass}` }}
      onChange={(_, group) => {
        const { acId, GroupName } = group as GroupITF
        if (onChange) {
          return onChange(group)
        }
        dispatch(selectedGroup({ groupId: acId, groupName: GroupName[locale], group }))
        updateGroup({ currentGroupId: acId, currentGroupName: GroupName[locale] })
        dispatch(selectedProperty({ propertyId: '', propertyName: '', buildingCode: '', propertyTag: {} }))
        if (ManageEntities) {
          redirectTo('/properties')
        } else {
          redirectTo('/')
        }
      }}
      value={option as GroupITF}
      style={style}
      options={showGroups || []}
      getOptionLabel={(option: GroupITF) => option.GroupName?.[locale] || ''}
      isOptionEqualToValue={(option: GroupITF, value: GroupITF) => option.acId === value?.acId}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder={translate('pos.search_client')}
          label={label}
          variant="outlined"
        />
      )}
      popupIcon={<ExpandMore />}
    />
  )
}

interface Props {
  onlyShowDefault?: boolean
}

const AdminSearch: React.FC<Props> = ({ onlyShowDefault }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const logout = useLogout()
  const dispatch = useDispatch()
  const redirectTo = useRedirect()
  const { ManageGroups } = getPermissions() || {}

  const oncreate = () => {
    redirectTo('/accountCreate')
  }
  const handleClick = async () => {
    await logout()
    dispatch(resetReducer({}))
  }
  return (
    <Box className={classes.root}>
      <Box sx={{ textAlign: 'left' }}>
        <img src={ACTIVATE_COMPANY_LOGO} style={{ height: 200, width: 600 }} alt="group logo" />
        <Box className={classes.inputPosition}>
          <AccountSearch style={{ width: '100%', maxWidth: 500 }} />
          {ManageGroups && (
            <Box className={classes.button} onClick={oncreate}>
              <AddCircle style={{ fontSize: 40, color: '#386BBF', marginRight: 7 }} />
              {translate('pos.create_client')}
            </Box>
          )}
        </Box>
      </Box>
      <Button className={classes.logout} onClick={handleClick}>
        Logout
      </Button>
    </Box>
  )
}

export default React.memo(AdminSearch)
export const AccountSearch = React.memo(Search)
