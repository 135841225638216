import { QRCodeFunctionType } from '../types'
import { PanelChoice } from '@/types/panel'

export enum COLOR {
  grey94 = '#949494',
  grey91 = '#919191',
  grey78 = '#787D80',
  grey43 = '#434343',
  grey50 = '#504C4C',
  grey37 = '#374053',
  black11 = '#111820',
  theme = '#386BBF',
  darkTheme = '#4267B3',
}

export const PDF_MAP = {
  [QRCodeFunctionType.Video]: {
    btnText: 'Watch Video',
    desc: 'This is the contents of the file,\nplease click the view button to enter\nthe details page.',
  },
  [QRCodeFunctionType.EmbeddedStaticUrl]: {
    btnText: 'Open Website',
    desc: 'please click the view button to enter\nthe details page.',
  },
  [QRCodeFunctionType.PDF]: {
    btnText: 'View PDF',
    desc: 'This is the contents of the file,\nplease click the view button to enter\nthe details page.',
  },
}

export const QRCodeFunctionTypeName = {
  [QRCodeFunctionType.Maintenance]: {
    text: 'Maintenance\nRequest',
    subText: 'Thanks for submitting! We will work on your request as soon as possible',
  },
  [QRCodeFunctionType.Feedback]: { text: 'Feedback Sent', subText: 'Thank you' },
  [QRCodeFunctionType.SupplierCheckin]: { text: '', subText: '' },
  [QRCodeFunctionType.Purchase]: { text: '', subText: 'Your Request has been submitted' },
  [QRCodeFunctionType.Communication]: { text: 'Request Sent', subText: 'Your Request has been submitted' },
  [QRCodeFunctionType.FieldMarketing]: {
    text: 'Request Sent',
    subText: 'We’ll respond to your request as soon as we can.',
  },
  [QRCodeFunctionType.FieldEvent]: {
    text: 'Request Sent',
    subText: 'We’ll respond to your request as soon as we can.',
  },
  [QRCodeFunctionType.Vacancy]: { text: 'Request Sent', subText: 'We’ll respond to your request as soon as we can.' },
  [QRCodeFunctionType.Renewal]: { text: 'Response Sent!', subText: 'We appreciate you letting us know' },
  [QRCodeFunctionType.MoveIn]: { text: 'Response Sent!', subText: 'Thank you for submitting your information!' },
  [QRCodeFunctionType.MoveOut]: { text: 'Response Sent!', subText: 'Thank you for submitting your information!' },
  [QRCodeFunctionType.SocialMedia]: {},
  [QRCodeFunctionType.Announcements]: {},
  [QRCodeFunctionType.Support]: {
    text: 'Request Sent',
    subText: 'Your Request has been submitted. We will be in touch soon!',
  },
  [QRCodeFunctionType.EventRegistration]: {
    text: 'Request Sent',
    subText: 'We’ll respond to your request as soon as we can.',
  },
  [QRCodeFunctionType.MoveOutResident]: {
    text: 'Response Sent!',
    subText: 'Thank you for submitting your information!',
  },
}

export const excelAccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'

export const PASSWORD_TIPS = [
  'At least 10 characters (and up to 100 characters)',
  '5 or more unique characters',
  'At least 3 of the following: uppercase, lowercase, numeric, or special characters.',
  'The allowed special characters are ~ ! @ # $ % ^ * - _ = + [ { ] } / ; : , . ? [no spaces allowed!]',
]

export const dateFormat = {
  locales: 'en-US',
  options: { year: '2-digit', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions,
}

export enum PUSHER_EVENT {
  savedround = '/events/savedround',
}

export const NOTE_CATEGORY = [
  { label: 'Copy', value: 'Copy' },
  { label: 'Content', value: 'Content' },
  { label: 'Other', value: 'Other' },
]
export const TIME_CONSTANT = {
  Milli_Second_OF_Day: 24 * 60 * 60 * 1000,
}

export const REQUEST_TYPE_CHOICE: PanelChoice[] = [
  {
    label: 'Error',
    value: 'Error',
  },
  {
    label: 'Change Request',
    value: 'Change Request',
  },
]
