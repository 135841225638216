import * as React from 'react'
import { forwardRef } from 'react'
import { useLogout } from 'react-admin'
import { useDispatch } from 'react-redux'
import { Box, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExitIcon from '@mui/icons-material/PowerSettingsNew'
import { resetReducer } from '@reducer/profile'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.45)',
    flexDirection: 'row-reverse',
    marginLeft: 3,
    fontSize: 16,
    fontFamily: 'Roboto, Regular',
    //   height: 48,
    //   padding: '0 30px',
  },
})

const LogoutButton = forwardRef(function LogoutButton(props, ref) {
  const classes = useStyles()
  const logout = useLogout()
  const dispatch = useDispatch()
  const handleClick = async () => {
    await logout()
    dispatch(resetReducer())
  }
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <Box className={classes.root}>
        <ExitIcon />
        <Box ml={5} mt={-4}>
          Logout
        </Box>
      </Box>
    </MenuItem>
  )
})

export default LogoutButton
